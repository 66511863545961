<template>
  <v-card-text>
    <v-data-table
      :headers="headers"
      :items="nonVerifiedLeaders"
      fixed-header
      height="500px"
      :loading="tableLoading"
      loading-text="Loading... Please wait"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>Leaders</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-chip-group column>
            <v-chip class="ma-2" color="green" text-color="white" label filter>
              Verified
              <v-avatar right class="green darken-4">
                100
              </v-avatar>
            </v-chip>
            <v-chip class="ma-2" color="red" text-color="white" label filter>
              Non-Verified
              <v-avatar right class="red darken-4">
                50
              </v-avatar>
            </v-chip>
          </v-chip-group>
          <v-chip class="ma-2" label>
            Label
          </v-chip>

          <v-chip class="ma-2" color="pink" label text-color="white">
            <v-icon left>
              mdi-label
            </v-icon>
            Tags
          </v-chip>

          <v-chip class="ma-2" color="primary" label>
            <v-icon left>
              mdi-account-circle-outline
            </v-icon>
            John Leider
          </v-chip>
        </v-toolbar>
      </template>
    </v-data-table></v-card-text
  >
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";
// import { HollowDotsSpinner } from "epic-spinners";

export default {
  name: "Home",
  components: {
    // HollowDotsSpinner,
  },
  data: () => ({
    moment: moment,
    search: "",
    tableLoading: false,
    headers: [
      {
        text: "Sno",
        value: "index",
        sortable: false,
      },
      {
        text: "First Name",
        value: "first_name",
      },
      {
        text: "Middle Name",
        value: "middle_name",
      },
      {
        text: "Last Name",
        value: "last_name",
      },
      {
        text: "Alias",
        value: "alias",
      },
      {
        text: "Age",
        value: "age",
      },
      {
        text: "Gender",
        value: "gender",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Phone No",
        value: "phone_no",
      },
    ],
  }),
  computed: {
    ...mapGetters(["nonVerifiedLeaders"]),
    ...mapState(["response"]),
  },
  mounted() {
    this.$store.dispatch("getNonVerifedLeaders");
  },
};
</script>
<style scoped>
.custom-badge {
  background-color: darksalmon;
}
</style>
